.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.bottom-nav {
  display: flex;
  justify-content: space-around;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #f8f9fa;
  padding: 10px 0;
}

.bottom-nav a {
  flex: 1;
  text-align: center;
  text-decoration: none;
  color: #000;
  padding: 10px;
}
@keyframes diagonal-shimmer {
  0% {
    background-position: -100% -100%;
  }
  100% {
    background-position: 200% 200%;
  }
}

.active {
  font-weight: bold;
  background: linear-gradient(135deg, #B59252 25%, #e3d386 25%, #c6a964 50%, #B59252 50%, #B59252 75%, #000 75%, #000);
  background-size: 200% 200%;
  animation: diagonal-shimmer 4s infinite; /* Slowed down to 4 seconds */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}