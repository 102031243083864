
.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

th {
    background-color: #93632a;
    color: #fff;
}

.calculation {
    color: #007bff;
}

.sum {
    color: #d6c077;
}
