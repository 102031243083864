/* InputComponent.module.css */

.inputContainer {
    text-align: center;
    margin-bottom: 20px;
}

.title {
    color: #333;
    font-size: 24px;
    margin-bottom: 10px;
}

.formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.label {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    color: #555;
    align-content: flex-start;
}

.input {
    padding: 10px;
    margin-bottom: 16px;
    width: 100%;
    box-sizing: border-box;
}

.submitButton {
    padding: 10px;
    background-color: #d6c077;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submitButton:hover {
    background-color: #93632a;
}

.error {
    color: red;
    margin-top: 8px;
    font-size: 14px;
}
